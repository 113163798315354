<template>
     <div class="load-container" >
              <div class="loader">
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
                <span class="loader-block"></span>
              </div>

              <p class="loading-message">{{text}}</p>
            </div>
</template>
<script>
export default {
    props:{
        text:{
            type:String,
            required:true
        }
    }
}
</script>
<style >

</style>