<template>
  <div>
    <my-loader v-if="step==4" text="Connection To FinancePlus"></my-loader>
    <div v-if="step !=4" class="container-fluid p-0" if="hasProperty">
      <div id="finance-plus-widget-affordability-eligibility">
        <div class="container">
          <div class="finance-plus-widget-affordability-eligibility-wrapper">
            <div class="finance-plus-widget-affordability-eligibility-form">
              <div class="row">
                <!-- style={{display: this.props.mortgage ? "" : "none"}} -->
                <div class="col-md-12 affordability-eligibility-form-summary">
                  <div
                    class="finance-plus-widget-affordability-eligibility-form-summary single-page"
                  >
                    <div class="finance-plus-widget-affordability-eligibility-summary-content">
                      <div class="row">
                        <div class="col-md-8 offset-md-2">
                          <div
                            class="finance-plus-widget-affordability-eligibility-summary-content-bottom-text"
                          >
                            <div class="funds-summary-limit">
                              <p>Property Price</p>
                              <h2>{{computed_property_price | currency}}</h2>
                              <div class="funds-summary-limit-divider"></div>
                              <p>Maximum loanable Amount</p>
                              <h2>{{loanable_amount | currency}}</h2>
                              <div class="funds-summary-limit-divider"></div>

                              <form class="price-summary-form">
                                <div class="form-group">
                                  <div class="form-row mt-4">
                                    <div class="col-md-6 mt-2">
                                      <label>Equity Contribution</label>
                                    </div>

                                    <div class="col-md-6 mt-2">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text"
                                          >{{computed_equity_percentage + " %"}}</span>
                                        </div>
                                        <input
                                          type="text"
                                          name="equity_contribution"
                                          class="form-control"
                                          v-model="equity_string"
                                        />
                                      </div>
                                      <!-- <input
                                        type="text"
                                        name="equity_contribution"
                                        class="form-control"
                                        v-model="equity_string"
                                      />-->
                                    </div>

                                    <div class="col-md-12 mt-2">
                                      <div class="range-slider">
                                        <input
                                          ref="equity_range"
                                          class="range-slider__range"
                                          type="range"
                                          :min="min_equity"
                                          :max="max_equity"
                                          v-model="equity"
                                            style="height:6px"
                                        />
                                        <div class></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="summary-property-price-box">
                              <!-- <div class="price-card-inner">
                              <div class="price-from">Prices from</div>
                              <h2 class="property-price">{{computed_property_price | currency}}</h2>
                              </div>-->
                              <div class="property-mortage-highlight mt-3">
                                <div class="price-card-inner">
                                  <p class="property-mortgage-heading">Buy on a mortgage</p>
                                  <div class="mortgage-wrapper">
                                    <p
                                      class="property-mortage-value"
                                    >{{ monthly_repayment | currency}}</p>
                                    <div class="property-mortgage-freq">/month</div>
                                  </div>
                                </div>
                                <div class="separator"></div>
                                <div class="price-card-inner">
                                  <p class="property-repayment-heading">Payback Over</p>
                                  <div class="repayment-wrapper">
                                    <p class="property-repayment-period">{{tenure}}</p>
                                    <div class="property-repayment-freq">/Years</div>
                                  </div>
                                </div>
                              </div>
                              <a
                                class="side-bar-btn o-button"
                                @click="applyForMortgage()"
                                :style="{backgroundColor:primary_color,color:'#fff'}"
                              >Buy this property</a>
                            </div>
                          </div>

                          <div class="text-center p-3">
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 affordability-eligibility-form">
                  <div class="finance-plus-widget-form">
                    <div class="form-row">
                      <div class="col-md-8 mt-2">
                        <label>What is your Net monthly income?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input type="text" name="emi" class="form-control" v-model="net_income2" />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            v-model="net_income"
                            class="range-slider__range"
                            type="range"
                            value="100"
                            min="30000"
                            max="5000000"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">N30,000</div>
                            <div class="range-slider__range-max">N5,000,000</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-12 col-md-12">
                        <label>Do you have additional income?</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="additional_income"
                          v-model="show_additional_income"
                          value="1"
                          id="radio-one"
                          class="form-radio"
                        />
                        <label for="radio-one">Yes</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="additional_income"
                          v-model="show_additional_income"
                          value="0"
                          checked
                          id="radio-two"
                          class="form-radio"
                        />
                        <label for="radio-two">No</label>
                      </div>
                      <div class="input-group" v-if="show_additional_income==1">
                        <div class="input-group-prepend">
                          <span class="input-group-text px-4 bg-white">₦</span>
                        </div>
                        <input class="form-control" type="text" v-model="additional_income" />
                        <div class="input-group-append">
                          <span class="input-group-text px-3 bg-theme-button-group">Monthly</span>
                        </div>
                        <div class="invalid-feedback">Please enter a valid amount</div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-12 col-md-12">
                        <label>Do you have other loan obligations?</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="obligation"
                          v-model="show_monthly_obligation"
                          value="1"
                          id="radio-one"
                          class="form-radio"
                        />
                        <label for="radio-one">Yes</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="obligation"
                          v-model="show_monthly_obligation"
                          value="0"
                          checked
                          id="radio-two"
                          class="form-radio"
                        />
                        <label for="radio-two">No</label>
                      </div>
                      <div class="input-group" v-if="show_monthly_obligation==1">
                        <div class="input-group-prepend">
                          <span class="input-group-text px-4 bg-white">₦</span>
                        </div>
                        <input class="form-control" type="text" v-model="monthly_obligation" />
                        <div class="input-group-append">
                          <span class="input-group-text px-3 bg-theme-button-group">Monthly</span>
                        </div>
                        <div class="invalid-feedback">Please enter a valid amount</div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>Age?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          type="text"
                          name="age"
                          v-model="string_age"
                          class="form-control"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            v-model="age"
                            type="range"
                            min="20"
                            max="59"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">20</div>
                            <div class="range-slider__range-max">59</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>How long do you want this loan for?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        price-summary
                        <input
                          type="text"
                          name="age"
                          class="form-control"
                          v-model="tenure_string"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            type="range"
                            value="1"
                            min="5"
                            :max="max_tenure"
                            v-model="tenure"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">5</div>
                            <div class="range-slider__range-max">{{max_tenure}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>Loan interest rate?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          type="text"
                          name="rate"
                          class="form-control"
                          v-model="rate_string"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            type="range"
                            v-model="rate"
                            min="5"
                            max="30"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">5</div>
                            <div class="range-slider__range-max">30</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 affordability-eligibility-mobile-summary">
                  <div class="container px-0">
                    <div class="affordability-eligibility-mobile-summary-wrapper">
                      <div class="loan-amount-equity-contribution">
                        <div class="loan-amount">
                          <p>Loanable Amount</p>
                          <h2>{{loanable_amount | currency}}</h2>
                        </div>
                        <div class="divider"></div>
                        <div class="equity-contribution d-none d-sm-block">
                          <form style="width:300px">
                            <div class="form-group form-row">
                              <div class="col-md-6">
                                <label>Equity Contribution</label>
                              </div>

                              <div class="col-md-6">
                                <input
                                  type="text"
                                  name="equity_contribution"
                                  class="form-control"
                                  v-model="equity_string"
                                />
                              </div>

                              <div class="col-md-12 col-xs-12">
                                <div class="range-slider">
                                  <input
                                    ref="equity_range"
                                    class="range-slider__range"
                                    type="range"
                                    :min="min_equity"
                                    :max="max_equity"
                                    v-model="equity"
                                  />
                                  <div class></div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="divider"></div>
                        <div class="loan-amount">
                          <p>Buy on a mortgage</p>
                          <h2>
                            {{monthly_repayment | currency}}
                            <sup>/monthly</sup>
                          </h2>
                        </div>
                      </div>

                      <div class="loan-amount-equity-contribution d-lg-none d-xl-block d-md-none">
                        <div class="equity-contribution">
                          <form>
                            <div class="form-group form-row">
                              <div class="col-md-8 mt-2">
                                <label>Equity Contribution</label>
                              </div>

                              <div class="col-md-4 mt-2">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text"
                                    >{{computed_equity_percentage + " %"}}</span>
                                  </div>
                                  <input
                                    type="text"
                                    name="equity_contribution"
                                    class="form-control"
                                    v-model="equity_string"
                                  />
                                </div>
                                <!-- <input
                                  type="text"
                                  name="equity_contribution"
                                  class="form-control"
                                  v-model="equity_string"
                                />-->
                              </div>

                              <div class="col-md-12 mt-2 col-xs-12">
                                <div class="range-slider">
                                  <input
                                    ref="equity_range"
                                    class="range-slider__range"
                                    type="range"
                                    :min="min_equity"
                                    :max="max_equity"
                                    v-model="equity"
                                    style="height:6px"
                                  />
                                  <div class></div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="loan-amount-equity-contribution">
                        <div class="loan-amount">
                          <p>Prices From</p>
                          <h2>{{computed_property_price | currency}}</h2>
                        </div>
                        <div class="divider"></div>
                        <div class="loan-amount property-title">
                          <p>Property title</p>
                          <h4>Certificate Of Occupancy</h4>
                        </div>
                        <div class="divider"></div>
                        <div class="loan-amount">
                          <p>Payback Over</p>
                          <h4>
                            {{tenure}}
                            <sup>/Years</sup>
                          </h4>Buy
                        </div>
                      </div>
                      <div class="loan-amount-equity-contribution-cta">
                        <a
                          class="buy-this-property text-white"
                          :style="{backgroundColor:primary_color,color:'#fff'}"
                          @click="applyForMortgage()"
                        >Buy this property</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default {
  props: ["property_value", "url", "developer", "primary_color"],
  data() {
    return {
      step: 1,
      show_modal: false,
      property_suggestion_cap: 2000000,
      show_additional_income: 0,
      show_monthly_obligation: 0,
      income_percentage: 0.333,
      net_income: 30000,
      net_income2: 30000,
      additional_income: 0,
      monthly_obligation: 0,
      isLoading: false,
      age: 20,
      string_age: "20 Years",
      tenure: 5,
      tenure_string: "5 Years",
      rate: 18,
      rate_string: "18 %",
      mr: 0,
      lm: 0,
      equity: 0,
      eq: 0,
      property_value_input: "34,000,000",
      loanable_amount_value: 0,
      equity_string: 0,
      min_equity: 0,
      max_equity: 0,
      equity_percenage:20,
      max_lm: 0,
      equity_error: false,
      equity_error_msg: "",
      solve_eligibility: false,
      affordability: {
        monthly_income: 0,
        additional_income: 0,
        monthly_obligation: 0,
        age: 20,
        rate: 18,
        tenure: 5,
        property_value: 0,
        loanable_amount: 0,
        mothly_repayment: 0
      },

      properties: [],

      rules: {
        required: value => !!value || "value is required"
      }
    };
  },
  watch: {
    net_income: function(val) {
      const result = this.currencyFormat(val);
      // .replace(/\D/g, "")
      // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.solve_eligibility = false;
      Vue.nextTick(() => (this.net_income2 = result));
    },
    net_income2: function(val) {
      const t = this.removeCommaFromNumber(val);
      this.solve_eligibility = false;
      Vue.nextTick(() => (this.net_income = t));
    },
    age: function(val) {
      const result = val;
      this.solve_eligibility = false;
      Vue.nextTick(() => (this.string_age = result));
    },
    tenure: function(val, old) {
      const result = val + " Years";
      this.solve_eligibility = false;
      Vue.nextTick(() => (this.tenure_string = result));
    },
    rate: function(val) {
      const result = val + " %";
      this.solve_eligibility = false;
      Vue.nextTick(() => (this.rate_string = result));
    },
    additional_income: function(v) {
      this.solve_eligibility = false;
    },
    monthly_obligation: function(v) {
      this.solve_eligibility = false;
    },
    lm: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.loanable_amount_value = result));
    },

    eq: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.equity = v));
      Vue.nextTick(() => (this.equity_string = result));
      this.equityIsUpdated();
    },
    equity: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.equity_string = result));
      this.equityIsUpdated();
    }
    // equity_string: function(v) {
    // const result = this.removeCommaFromNumber(v);
    //  this.validateEquity(v)
    // Vue.nextTick(() => (this.eq = result));

    // Vue.nextTick(()=>(this.eq = v ));
    // this.equityIsUpdated();
    // }
  },
  computed: {
    computed_property_price() {
      this.property_value_input = parseInt(
        this.removeCommaFromNumber(this.property_value)
      );
      return this.property_value_input;
    },
    activate_suggestion() {
      if (this.lm >= this.property_suggestion_cap) {
        return true;
      }
      return false;
    },
    loanable_amount() {
      if (this.solve_eligibility) {
        return this.lm;
      }
      this.calculateLoanableAmount();
      this.lookup();

      return this.lm;
    },
    monthly_repayment() {
      this.mr = Math.round(this.calculateMonthlyPayment(this.lm, this.rate, this.tenure));
      return this.mr;
    },
    computed_equity_percentage(){
      return this.equity_percenage;
    },
    max_tenure() {
      let retire_age = 60;
      let age_diff = retire_age - this.age;
      if (age_diff > 20) {
        return 20;
      }
      this.tenure = age_diff;
      return age_diff;
    }
  },
  methods: {
    removeCommaFromNumber(v) {
      return parseInt(v.replace(/,/g, ""));
    },
    formatToCommaSeperated(val) {
      const result = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result;
    },

    currencyFormat(v) {
      return Number(v).toLocaleString("en");
    },
    calculateAge(date_of_birth) {
      let today = new Date();
      let birthday = new Date(date_of_birth);
      let age = today.getFullYear() - birthday.getFullYear();
      return age;
    },

    calculateMonthlyPayment(principal, r, year) {
      let months = Number(year * 12);
      let rate = parseFloat(r / 100 / 12);

      let rate_plus_one = 1 + rate;
      let rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
      let numerator = principal * rate * rate_raise_to_Number_of_month;
      let denomirator = rate_raise_to_Number_of_month - 1;
      let monthly_payment = (numerator / denomirator).toFixed(2);
      return monthly_payment;
    },
    lookup() {
      let p = this.property_value_input;
        this.max_lm = 0.8 * p;
        this.min_equity = 0.2 * p;
      if(this.lm > this.max_lm){
        this.lm = this.max_lm;
        this.eq = p - this.lm;
        this.equity_percenage = this.calculatePercentage(this.eq)
      }
      else if (this.lm == this.max_lm) {
          this.eq = 0.2 * p;
          this.lm = this.max_lm
          this.max_equity = 0.8 * p;
          this.equity_percenage = this.calculatePercentage(this.eq)
      } else {
        this.eq = p - this.lm;
        // this.min_equity = this.eq;
        this.max_equity = 0.8 * p;
        this.max_lm = this.lm;
          this.equity_percenage = this.calculatePercentage(this.eq)
      }
    },
    equityIsUpdated() {
      this.solve_eligibility = true;
      let p = this.property_value_input;
      this.lm = p - this.equity;
      this.mr = this.calculateMonthlyPayment(this.lm, this.rate, this.tenure);
      this.equity_percenage = this.calculatePercentage(this.equity)
      //  return y;
    },

    calculateLoanableAmount() {
      let income = this.net_income;
      let inc = 0;
      if (this.show_additional_income != 0) {
        income += Number(this.additional_income);
      }
      if (this.show_monthly_obligation != 0) {
        income -= Number(this.monthly_obligation);
      }
      let r = this.rate;
      let y = this.tenure;
      let months = y * 12;
      let rate = r / 100 / 12;
       let rate_plus_one = 1 + rate;
      let rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
      let raise_to_power_month_minus_one = rate_raise_to_Number_of_month - 1;
      let monthly_payment = Number(income) * this.income_percentage;
      let numerator = monthly_payment * raise_to_power_month_minus_one;
      let denominator = rate * rate_raise_to_Number_of_month;
      let loanable_amount = numerator / denominator;
      
      this.lm = Math.round(loanable_amount);
      // this.lookup();
    },
    calculatePercentage(v){
        let p = this.property_value_input;
        let q = v/p;
        return (q*100).toFixed();
    },
    showPropertiesSuggestion() {
      this.isLoading = true;
      this.showModal();
      axios
        .get(this.url)
        .then(res => {
          this.properties = res.data.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    showModal() {
      this.step = 2;
      this.show_modal = true;
      // $("#suggestionModal").modal("show");
    },
    closeModal() {
      this.show_modal = false;
      $(".modal-backdrop").remove();
      // this.step = 1;
    },
    // formoperation
    goStep(step) {
      if (step == 2) {
        this.step = step;
        this.showModal();
      } else {
        this.suggestion_sheet = false;
      }
    },

    goToEligibility(property_value) {
      this.step = 3;
      this.solve_eligibility = true;
      this.property_value_input = property_value;
      this.closeModal();
    },
    applyForMortgage() {
      this.step = 4;
      let y = this;
      this.affordability.monthly_income = this.net_income;
      this.affordability.age = this.age;
      this.affordability.property_value = this.property_value_input;
      this.affordability.monthly_obligation = this.monthly_obligation;
      this.affordability.additional_income = this.additional_income;
      this.affordability.loanable_amount = this.lm;
      this.affordability.equity = this.equity;
      this.affordability.rate = this.rate;
      this.affordability.tenure = this.tenure;
      this.affordability.mothly_repayment = this.mr;

      // console.log("new form", this.affordability)

      axios
        .post(
          "https://app.newhomes.ng/api/developerapp/" +
            this.developer +
            "/affordability-test",
          this.affordability
        )
        .then(res => {
          localStorage.setItem(
            "financeplus_test",
            JSON.stringify(this.affordability)
          );
          console.log(res.data.data);
          setTimeout(function() {
            y.step = 1;
            y.linkOut();
          }, 1000);
        })
        .catch(err => {});
    },
    linkOut() {
      let u = "https://financeplus.ng/mortgage/application/redirect";
      return (window.location.href = u);
    }
  },
  mounted() {
    this.property_value_input = this.property_value;
  }
};
</script>
<style>
.side-bar-btn {
  width: 100%;
  display: inline-block;
  margin-top: 16px;
  padding: 15px 13px;
  border-radius: 5px;
  /* background-color: #136b3d; */
  font-family: "Droid Sans", sans-serif;
  text-align: center;
  color: #fff;
}
/* .o-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #136b3d;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
} */
</style>
