<template>
  <div id="app">
    <my-loader v-if="step==4" text="Connection To FinancePlus"></my-loader>

    <div class="container-fluid p-0" v-if="step != 4">
      <!-- Navigation-->

      <div id="finance-plus-widget-affordability-eligibility" class="full">
        <div class="container">
          <div class="finance-plus-widget-affordability-eligibility-wrapper">
            <h2 class="mt-4">
              <span>
                Check your
                <strong>Eligibility</strong>
              </span>
              <!-- <i class="glyphicon glyphicon-refresh"></i> -->
            </h2>
            <div class="finance-plus-widget-affordability-eligibility-form">
              <div class="row">
                <div class="col-md-6 affordability-eligibility-form">
                  <div class="finance-plus-widget-form" v-if="step==1 || step==2">
                    <div class="form-row">
                      <div class="col-md-8 mt-2">
                        <label>What is your Net monthly income?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          v-model="net_income2"
                          type="text"
                          name="emi"
                          class="form-control"
                          value="1000000"
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            v-model="net_income"
                            class="range-slider__range"
                            type="range"
                            value="100"
                            min="30000"
                            max="5000000"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">N30,000</div>
                            <div class="range-slider__range-max">N5,000,000</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row mt-4">
                      <div class="col-12 col-md-12">
                        <label>Do you have additional income?</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="additional_income"
                          v-model="show_additional_income"
                          value="1"
                          id="radio-one"
                          class="form-radio"
                        />
                        <label for="radio-one">Yes</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="additional_income"
                          v-model="show_additional_income"
                          value="0"
                          checked
                          id="radio-two"
                          class="form-radio"
                        />
                        <label for="radio-two">No</label>
                      </div>
                      <div class="input-group" v-if="show_additional_income==1">
                        <div class="input-group-prepend">
                          <span class="input-group-text px-4 bg-white">₦</span>
                        </div>
                        <input class="form-control" type="text" v-model="additional_income" />
                        <div class="input-group-append">
                          <span class="input-group-text px-3 bg-theme-button-group">Monthly</span>
                        </div>
                        <div class="invalid-feedback">Please enter a valid amount</div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-12 col-md-12">
                        <label>Do you have other loan obligations?</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="obligation"
                          v-model="show_monthly_obligation"
                          value="1"
                          id="radio-one"
                          class="form-radio"
                        />
                        <label for="radio-one">Yes</label>
                      </div>

                      <div class="col-12 col-md-6 mt-2">
                        <input
                          type="radio"
                          name="obligation"
                          v-model="show_monthly_obligation"
                          value="0"
                          checked
                          id="radio-two"
                          class="form-radio"
                        />
                        <label for="radio-two">No</label>
                      </div>
                      <div class="input-group" v-if="show_monthly_obligation==1">
                        <div class="input-group-prepend">
                          <span class="input-group-text px-4 bg-white">₦</span>
                        </div>
                        <input class="form-control" type="text" v-model="monthly_obligation" />
                        <div class="input-group-append">
                          <span class="input-group-text px-3 bg-theme-button-group">Monthly</span>
                        </div>
                        <div class="invalid-feedback">Please enter a valid amount</div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>Age?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          type="text"
                          name="age"
                          v-model="string_age"
                          class="form-control"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            v-model="age"
                            type="range"
                            min="20"
                            max="59"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">20</div>
                            <div class="range-slider__range-max">59</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>How long do you want this loan for?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          type="text"
                          name="age"
                          class="form-control"
                          v-model="tenure_string"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            type="range"
                            value="1"
                            min="5"
                            :max="max_tenure"
                            v-model="tenure"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">5</div>
                            <div class="range-slider__range-max">{{max_tenure}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-8 mt-2">
                        <label>Loan interest rate?</label>
                      </div>

                      <div class="col-md-4 mt-2">
                        <input
                          type="text"
                          name="rate"
                          class="form-control"
                          v-model="rate_string"
                          readonly
                        />
                      </div>

                      <div class="col-md-12 mt-2">
                        <div class="range-slider">
                          <input
                            class="range-slider__range"
                            type="range"
                            v-model="rate"
                            min="5"
                            max="30"
                          />
                          <div class="d-flex justify-content-between mt-2">
                            <div class="range-slider__range-min">5</div>
                            <div class="range-slider__range-max">30</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row mt-4">
                      <div class="col-md-12" v-if="activate_suggestion">
                        <a
                          v-if="step==1 ||step==2"
                          data-toggle="modal"
                          data-target="#suggestionModal"
                          @click="showPropertiesSuggestion()"
                          class="mobile-view-matching-properties"
                          :style="{backgroundColor:primary_color,border:primary_color}"
                        >View Suggested Properties</a>
                        <a
                          v-if="step==3"
                          @click="applyForMortgage()"
                          class="mobile-view-matching-properties"
                          :style="{backgroundColor:primary_color,border:primary_color}"
                        >Apply For Mortgage</a>
                        <!-- <button
                          @click="showModal()"
                          class="mobile-view-matching-properties"
                        >View Suggested Properties</button>-->
                      </div>
                    </div>
                  </div>

                  <div class="finance-plus-widget-affordability-eligibility-form" v-if="step==3">
                    <div class="affordability-eligibility-form">
                      <div class="finance-plus-widget-form">
                        <div class="form-row">
                          <div class="col-md-12 mt-2">
                            <label>Property Value?</label>

                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text px-4 bg-white">₦</span>
                              </div>
                              <input
                                class="form-control"
                                readonly
                                type="text"
                                name="property_name"
                                v-model="formatted_property_value"
                              />
                              <div class="invalid-feedback">Please enter a valid amount</div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row mt-4">
                          <div class="col-md-4 mt-2">
                            <label>Equity Contribution</label>
                          </div>

                          <div class="col-md-8 mt-2">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">{{computed_equity_percentage + " %"}}</span>
                              </div>
                              <input
                                type="text"
                                name="equity_contribution"
                                class="form-control"
                                v-model="equity_string"
                              />
                            </div>
                            <!-- <input type="text" class="form-control" v-model="equity_string" /> -->
                          </div>

                          <div class="col-md-12 mt-2">
                            <div class="range-slider">
                              <input
                                ref="equity_range"
                                class="range-slider__range"
                                type="range"
                                :min="min_equity"
                                :max="max_equity"
                                v-model="equity"
                              />
                              <div class></div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row mt-4">
                          <div class="col-md-12 mt-2">
                            <label>Loanable Amount</label>

                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text px-4 bg-white">₦</span>
                              </div>
                              <input
                                class="form-control"
                                type="text"
                                readonly
                                v-model="loanable_amount_value"
                              />
                              <div class="invalid-feedback">Please enter a valid amount</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 affordability-eligibility-form-summary">
                  <div class="finance-plus-widget-affordability-eligibility-form-summary">
                    <div class="finance-plus-widget-affordability-eligibility-summary-content">
                      <div
                        class="finance-plus-widget-affordability-eligibility-summary-content-header"
                        :style="{backgroundColor:primary_color, border:primary_color}"
                      >
                        <div
                          class="finance-plus-widget-affordability-eligibility-summary-content-header-text"
                        >
                          <i class="fe fe-check-circle"></i>
                          <p>You are Eligible for this amount</p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-10 offset-md-1">
                          <div
                            class="finance-plus-widget-affordability-eligibility-summary-content-bottom-text"
                          >
                            <div class="funds-summary-limit">
                              <p>Maximum loanable Amount</p>
                              <h2>{{ loanable_amount | currency}}</h2>
                              <div class="funds-summary-limit-divider"></div>
                              <p>Estimated Monthly Repayment</p>
                              <h2>{{monthly_repayment | currency}}</h2>
                            </div>
                          </div>

                          <div class="property-funding-wrapper align-self-center">
                            <a
                              v-if="step==1||step==2"
                              data-toggle="modal"
                              data-target="#suggestionModal"
                              @click="showPropertiesSuggestion()"
                              class="view-matching-properties"
                              :style="{backgroundColor:primary_color,border:primary_color}"
                            >View Suggested Properties</a>
                            <a
                              v-if="step==3"
                              @click="applyForMortgage()"
                              class="view-matching-properties"
                              :style="{backgroundColor:primary_color,border:primary_color}"
                            >Apply For Mortgage</a>
                          </div>

                          <div class="poweredBy text-center p-3 mt-5">
                            <!-- <p>
                              Powered by
                              <img src="../assets/img/f-logo.png" alt="Home Finance Logo" />
                            </p>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 affordability-eligibility-mobile-summary">
                  <div class="container">
                    <div class="affordability-eligibility-mobile-summary-wrapper">
                      <div class="loan-amount-equity-contribution">
                        <div class="loan-amount">
                          <p>Loanable Amount</p>
                          <h2>{{ loanable_amount | currency}}</h2>
                        </div>
                        <div class="divider"></div>
                        <div class="equity-contribution d-none d-sm-block">
                          <!-- <form>
                            <div class='form-group'>
                              <label>Equity Contributions</label>
                              <input
                                type='number'
                                placeholder=''
                                value='30,000,000'
                                name='equity_contribution'
                              />
                            </div>
                          </form>-->
                        </div>
                        <div class="divider"></div>
                        <div class="loan-amount">
                          <p>Buy on a mortgage</p>
                          <h2>
                            {{monthly_repayment | currency}}
                            <sup>/monthly</sup>
                          </h2>
                        </div>
                      </div>

                      <div class="loan-amount-equity-contribution d-lg-none d-xl-block d-md-none">
                        <div class="equity-contribution">
                          <!-- <form>
                            <div class='form-group'>
                              <label>Equity Contributions</label>
                              <input
                                type='number'
                                placeholder=''
                                value='30,000,000'
                                name='equity_contribution'
                              />
                            </div>
                          </form>-->
                        </div>
                      </div>

                      <!-- <div class='loan-amount-equity-contribution'>
                        <div class='loan-amount'>
                          <p>Prices From</p>
                          <h2>N30,000,000</h2>
                        </div>
                        <div class='divider'></div>
                        <div class='loan-amount property-title'>
                          <p>Property title</p>
                          <h4>Certificate Of Occupancy</h4>
                        </div>
                        <div class='divider'></div>
                        <div class='loan-amount'>
                          <p>Payback over</p>
                          <h4>
                            20 <sup>/Years</sup>
                          </h4>
                        </div>
                      </div>-->
                      <div class="loan-amount-equity-contribution-cta">
                        <a
                          v-if="step ==1 || step ==2"
                          data-toggle="modal"
                          data-target="#suggestionModal"
                          @click="showPropertiesSuggestion()"
                          class="buy-this-property"
                          :style="{backgroundColor:primary_color,border:primary_color, color:'#fff'}"
                        >View Suggested Properties</a>
                        <a
                          v-if="step==3"
                          @click="applyForMortgage()"
                          class="buy-this-property"
                          :style="{backgroundColor:primary_color,border:primary_color,color:'#fff'}"
                        >Apply For Mortgage</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="suggestionModal" v-if="show_modal" class="modal fade">
            <div class="finance-widget-property-wrapper">
              <div class="container-fluid">
                <my-loader v-if="isLoading" text="Searching Properties"></my-loader>
                <div class="text-center">
                  <button class="btn modal-close" v-if="!isLoading" @click="closeModal()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>

                <div class="finance-widget-property-wrapper-list" v-if="!isLoading">
                  <div class="finance-widget-property-list" v-for="(p,i) in properties" :key="i">
                    <div class="property-card">
                      <div
                        class="property-image-holder"
                        :style="{backgroundImage:'url('+p.property_cover_image+')'}"
                      ></div>

                      <div class="property-image-details">
                        <div class="property-card-price-holder">
                          <div class="property-card-property-status">{{p.property_status}}</div>
                          <h4 class="property-card-price">{{p.property_price| ready_currency}}</h4>
                        </div>

                        <div class="property-card-name-holder">
                          <h3 class="property-card-title">{{p.property_name}}</h3>
                          <div class="property-card-extras">
                            <div class="property-card-location">{{p.property_city}}</div>
                          </div>
                        </div>

                        <div class="property-card-bed-bath-holder">
                          <div class="property-card-inner-side">
                            <div class="property-card-icon"></div>
                            <div class="property-card-bed-bath">{{p.property_bedrooms}} Beds</div>
                          </div>
                          <div class="property-card-inner-divider"></div>
                          <div class="property-card-inner-side">
                            <div class="property-card-icon"></div>
                            <div class="property-card-bed-bath">{{p.property_bathrooms}} Baths</div>
                          </div>
                          <div class="property-card-inner-divider"></div>
                          <div class="property-card-inner-side">
                            <div class="property-card-icon"></div>
                            <div class="property-card-bed-bath">{{p.property_size}} Sqm</div>
                          </div>
                        </div>

                        <div class="property-mortgage-deets-holder">
                          <div class="property-card-inner-block">
                            <p class="property-mortgage-heading">Buy on a mortgage</p>
                            <div class="property-mortgage-wrapper">
                              <p
                                class="property-mortage-value"
                              >{{calculateMonthlyPayment(removeCommaFromNumber(p.property_price),18,20) | currency }}</p>
                              <div class="property-mortgage-freq">/mth</div>
                            </div>
                          </div>
                          <div class="property-card-inner-divider long"></div>
                          <div class="property-card-inner-block">
                            <p class="property-repayment-heading">Payback over</p>
                            <div class="property-repayment-wrapper">
                              <p class="property-repayment-period">20</p>
                              <div class="property-repayment-freq">/Years</div>
                            </div>
                          </div>
                        </div>

                        <a
                          class="property-project-card-cta o-button"
                          @click="goToEligibility(p.property_price)"
                        >Buy This Property</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default {
  props: ["property_value", "url", "developer", "primary_color"],
  data() {
    return {
      step: 1,
      show_modal: false,
      property_suggestion_cap: 2000000,
      show_additional_income: 0,
      show_monthly_obligation: 0,
      income_percentage: 0.333,
      net_income: 30000,
      net_income2: 30000,
      additional_income: 0,
      additional_income_string: 0,
      monthly_obligation: 0,
      monthly_obligation_string: 0,
      isLoading: false,
      age: 20,
      string_age: "20 Years",
      tenure: 5,
      tenure_string: "5 Years",
      rate: 18,
      rate_string: "18 %",
      mr: 0,
      lm: 0,
      equity: 0,
      eq: 0,
      property_value_input: "34,000,000",
      loanable_amount_value: 0,
      equity_string: 0,
      min_equity: 0,
      max_equity: 0,
      equity_percenage: 20,
      equity_error: false,
      equity_error_msg: "",
      max_lm: 0,
      solve_eligibility: false,
      affordability: {
        monthly_income: 0,
        additional_income: 0,
        monthly_obligation: 0,
        age: 20,
        rate: 18,
        tenure: 5,
        property_value: 0,
        loanable_amount: 0,
        mothly_repayment: 0
      },

      properties: [],

      rules: {
        required: value => !!value || "value is required"
      }
    };
  },
  watch: {
    net_income: function(val) {
      const result = this.currencyFormat(val);
      // .replace(/\D/g, "")
      // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.net_income2 = result));
      // this.lookup();
    },
    net_income2: function(val) {
      const t = this.removeCommaFromNumber(val);
      Vue.nextTick(() => (this.net_income = t));
      // this.net_income = t;
    },
    additional_income_string: function(v) {
      if (typeof v == "number") {
        const r = this.removeCommaFromNumber(v);
        Vue.nextTick(() => (this.additional_income = r));
      } else {
      }
    },
    additional_income: function(v) {
      const r = this.currencyFormat(v);
      Vue.nextTick(() => (this.additional_income_string = r));
    },
    // },
    age: function(val) {
      const result = val;
      Vue.nextTick(() => (this.string_age = result));
    },
    tenure: function(val, old) {
      const result = val + " Years";
      Vue.nextTick(() => (this.tenure_string = result));
    },
    rate: function(val) {
      const result = val + " %";
      Vue.nextTick(() => (this.rate_string = result));
    },
    lm: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.loanable_amount_value = result));
    },
    mr: function(v) {},

    eq: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.equity = v));
      Vue.nextTick(() => (this.equity_string = result));
      // console.log("eqhh",this.equity)
      this.equityIsUpdated();
    },
    equity: function(v) {
      const result = this.currencyFormat(v);
      Vue.nextTick(() => (this.equity_string = result));
      this.equityIsUpdated();
    }
  },
  computed: {
    activate_suggestion() {
      if (this.lm >= this.property_suggestion_cap) {
        return true;
      }
      return false;
    },
    formatted_property_value() {
      return this.currencyFormat(this.property_value_input);
    },
    loanable_amount() {
      if (this.solve_eligibility) {
        return Math.round(this.lm);
      }
      this.calculateLoanableAmount();

      return this.lm;
    },
    second_loanable_amount() {
      return this.currencyFormat(this.lm);
    },
    monthly_repayment() {
      return Math.round(this.mr);
    },
    max_tenure() {
      let retire_age = 60;
      let age_diff = retire_age - this.age;
      if (age_diff > 20) {
        return 20;
      }
      this.tenure = age_diff;
      return age_diff;
    },
    computed_equity_percentage() {
      return this.equity_percenage;
    }
  },
  methods: {
    removeCommaFromNumber(v) {
      return parseInt(v.replace(/,/g, ""));
    },
    formatToCommaSeperated(val) {
      const result = val
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result;
    },

    currencyFormat(v) {
      return Number(v).toLocaleString("en");
    },
    calculateAge(date_of_birth) {
      let today = new Date();
      let birthday = new Date(date_of_birth);
      let age = today.getFullYear() - birthday.getFullYear();
      return age;
    },

    calculateMonthlyPayment(principal, r, year) {
      let months = Number(year * 12);
      let rate = parseFloat(r / 100 / 12);

      let rate_plus_one = 1 + rate;
      let rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
      let numerator = principal * rate * rate_raise_to_Number_of_month;
      let denomirator = rate_raise_to_Number_of_month - 1;
      let monthly_payment = (numerator / denomirator).toFixed(0);
      return monthly_payment;
    },
    lookup() {
      let p = this.property_value_input;
      this.max_lm = 0.8 * p;
      this.min_equity = 0.2 * p;
      this.max_equity = 0.8 * p;
      if (this.lm > this.max_lm) {
        this.lm = this.roundNumberUp(this.max_lm);
        this.eq = this.roundNumberUp(p - this.lm);
        this.equity_percenage = this.calculatePercentage(this.eq);
      } else if (this.lm == this.max_lm) {
        this.eq = this.roundNumberUp(0.2 * p);
        this.lm = this.roundNumberUp(this.max_lm);
        this.max_equity = 0.8 * p;
        this.equity_percenage = this.calculatePercentage(this.eq);
      } else {
        this.eq = this.roundNumberUp(p - this.lm);
        // this.min_equity = this.eq;
        this.max_equity = 0.8 * p;
        this.max_lm = this.lm;
        this.equity_percenage = this.calculatePercentage(this.eq);
      }
    },
    calculatePercentage(v) {
      let p = this.property_value_input;
      let q = v / p;
      return (q * 100).toFixed();
    },
    roundNumberUp(v) {
      return Math.round(v);
    },
    equityIsUpdated() {
      this.solve_eligibility = true;
      let p = this.property_value_input;
      this.lm = this.roundNumberUp(p - this.equity);
      this.mr = this.calculateMonthlyPayment(this.lm, this.rate, this.tenure);
      this.equity_percenage = this.calculatePercentage(this.equity);
      //  return y;
    },

    calculateLoanableAmount() {
      let income = this.net_income;
      let inc = 0;
      if (this.show_additional_income != 0) {
        income += Number(this.additional_income);
      }
      if (this.show_monthly_obligation != 0) {
        income -= Number(this.monthly_obligation);
      }

      let r = this.rate;
      let y = this.tenure;
      let months = y * 12;
      let rate = r / 100 / 12;
      let rate_plus_one = 1 + rate;
      let rate_raise_to_Number_of_month = Math.pow(rate_plus_one, months);
      let raise_to_power_month_minus_one = rate_raise_to_Number_of_month - 1;
      let monthly_payment = Number(income) * this.income_percentage;
      let numerator = monthly_payment * raise_to_power_month_minus_one;
      let denominator = rate * rate_raise_to_Number_of_month;
      let loanable_amount = numerator / denominator;
      this.mr = Math.round(this.calculateMonthlyPayment(loanable_amount, r, y));
      this.lm = Math.round(loanable_amount);
      if (this.solve_eligibility) {
        this.lookup();
      }
    },
    showPropertiesSuggestion() {
      this.isLoading = true;
      this.showModal();
      axios
        .get(this.url)
        .then(res => {
          this.properties = res.data.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    showModal() {
      this.step = 2;
      this.show_modal = true;
      // $("#suggestionModal").modal("show");
    },
    closeModal() {
      this.show_modal = false;
      //  $('#suggestionModal ').modal('hide');
      window.location = window.location;
      $(".modal-backdrop").remove();
      // this.step = 1;
    },
    // formoperation
    goStep(step) {
      if (step == 2) {
        this.step = step;
        this.showModal();
      } else {
        this.suggestion_sheet = false;
      }
    },

    goToEligibility(property_value) {
      this.step = 3;
      this.solve_eligibility = true;

      this.property_value_input = this.removeCommaFromNumber(property_value);
      console.log("pv", this.property_value_input);
      this.lookup();
      // close modal
      this.show_modal = false;
      $(".modal-backdrop").remove();
    },
    applyForMortgage() {
      this.step = 4;
      let y = this;
      // this.isLoading= true;
      this.affordability.monthly_income = this.net_income;
      this.affordability.age = this.age;
      this.affordability.property_value = this.property_value_input;
      this.affordability.monthly_obligation = this.monthly_obligation;
      this.affordability.additional_income = this.additional_income;
      this.affordability.loanable_amount = this.lm;
      this.affordability.equity = this.equity;
      this.affordability.rate = this.rate;
      this.affordability.tenure = this.tenure;
      this.affordability.mothly_repayment = this.mr;

      console.log("new form", this.affordability);

      axios
        .post(
          "https://app.newhomes.ng/api/developerapp/" +
            this.developer +
            "/affordability-test",
          this.affordability
        )
        .then(res => {
          localStorage.setItem(
            "financeplus_test",
            JSON.stringify(this.affordability)
          );
          setTimeout(function() {
            y.linkOut();
            y.step = 1;

            // y.isLoading=false
          }, 1000);
        })
        .catch(err => {});
    },
    linkOut() {
      let u = "https://financeplus.ng/mortgage/application/redirect";
      return (window.location.href = u);
    }
  },
  mounted() {
    // this.calculateLoanableAmount();
  }
};
</script>
<style>
.modal-close {
  width: 50px;
  height: 50px;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid #f6f8f9;
  background-color: #f6f8f9;
  position: absolute;
  top: -25px;
}
.modal-close:hover {
  color: #fff;
  background-color: #000;
}
</style>