import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import filter from './plugins/filter';
window.$ = require('jquery');
import vuescroll from 'vuescroll';
// import Popper from 'popper.js'
require('bootstrap');
// import './bootstrap/dist/js/bootstrap.js';
window.JQuery = require('jquery')
import 'bootstrap/dist/css/bootstrap.css';
import MyLoader from './components/MyLoader'

Vue.config.productionTip = false
import Single from './components/Single'
import All from './components/All'
// new Vue({
//   store,
//   render: h => h(App)
// }).$mount('#app')

Vue.component('single-property',Single);
Vue.component('all-property',All);

Vue.component('my-loader',MyLoader)

Vue.use(vueCustomElement)
Vue.use(vuescroll);
App.store = store
Vue.customElement('financeplus-widget', App)
