<template>
  <div id="app" :style="{fontFamilty:font}">
      <single-property :property_value="propertyValue" :primary_color="primaryColor" :url="url" :developer="developer"  v-if="layout"></single-property>
      <all-property :property_value="propertyValue" :primary_color="primaryColor" :url="url" :developer="developer"  v-if="!layout"></all-property>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
// import $ from 'jquery'
export default {
  props: {
    developer: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      default: "#00b1ab"
    },
    font: {
      type: String,
      default: "DM Sans, sans-serif"
    },
    hasProperty: {
      type: Boolean,
      default: false,
      required: true
    },
    propertyValue: {
      type: String,
      default: "0"
    }
  },

computed:{
     layout(){
      if(this.hasProperty){
        return true
      }
      return false
    },
}
};
</script>

<style>
@import url("./assets/css/main.css");
@import url("./assets/css/loader.css");
/*
.finance-widget-property-wrapper-list {
  display: flex;
  overflow: scroll;
}
.scrollinpropertyg-wrapper {
  -webkit-overflow-scrolling: touch;
}
.finance-widget-property-list {
  flex: 0 0 auto;
  margin: 5px 10px;
} */
</style>
